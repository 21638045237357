
#EditTitle { 
    display: flex;
    flex-direction: row;
    font-size: 3rem;
    align-content: center;
    align-items: center;
    margin-bottom:0.3rem;
}

#EditTitle img {
    height:4rem;
    margin-right:0.25rem;
}

#EditPane fieldset {
    padding:1rem 0.5rem;
}
#EditPane legend {
    padding:0.1rem 0.5rem;
    font-size:1rem;
}
#EditPane label {
    display:flex;
    margin-bottom:0.5rem;  
    align-items: center;  
}

#EditPane label .label-text {
    flex:1;

}
#EditPane label input, #EditPane label select {
    margin-left:1rem;
    flex:3;
    /*justify-self: flex-end;*/
}

#EditPane label:last-child { margin-bottom:0px;}

#EditPane .actions {
    display: flex;
    margin-top: 1rem;    
    align-items: center;
    justify-content: center;
    align-content: center;
    
}

#EditPane .actions button {

    flex:1;

}

/*
#EditPane .actions button svg {
    margin-right:0.5rem;
}*/