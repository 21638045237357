.itemOrcamento {
    border:solid var(--gray-line) 1px;
    background-color:var(--white);
    position: relative;
    cursor: pointer;
}

.itemOrcamento > .editLayer {
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    background-color:rgba(100,100,100,0.5);
    
    
}

.itemOrcamento > .editLayer.active {
    background-color:#FFFFFF88;
}

.itemOrcamento > .editLayer.active {

    background-color: #FFFFFF88;

}
.editLayer.active::after {

    content: " ";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    border-left: 1rem solid var(--green);
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    width: 0px;
    height: 0px;

}

.itemOrcamento dl {display:flex;}
.itemOrcamento img {height:3rem;}
.itemOrcamento dl > dt {margin-right:0.25rem;font-weight: bold;}
.itemOrcamento .thumb {justify-self: center;margin-right:0.5rem;display:flex;justify-items:center ;}
.itemOrcamento dl > dd::after{display:inline-flex;content:"|";margin:0ex 1ex;color:var(--text);font-weight: normal;}
.itemOrcamento dl > dd:last-child:after{content:"";margin:0;}
.item-qtd.alert {color:var(--red);font-weight:bold;}
.itemOrcamento .actions {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: stretch;
    justify-content: flex-end;
    flex: 1;
}

.itemOrcamento .actions button {margin-right:0.25rem;padding:0.25rem 0.5rem;border:0px;}
.itemOrcamento .actions button:last-of-type {margin-right:0rem;}
button.edit {
    background-color:var(--green);
    color:var(--white);
}

button.remove:hover svg {
    color:var(--red);
}

.valor-item-orcamento {
	align-self: center;
	flex: 1;
	text-align: right;
	margin-right: 1rem;
}