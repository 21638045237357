:root {
    --white: #fff;
    --background: #f2f3f5;
    --gray-line: #dcdde0;
    --text: #666666;
    --text-highlight: #b3b9ff;
    --title: #2e384d;
    --red: #e83f5b;
    --green: #4cd62b;
    --blue: #5965e0;
    --blue-dark: #4953b8;
    --blue-twitter: #2aa9e0;
  }

@media(max-width: 1080px) {
    html { font-size: 100% /*93.75%*/; }
}

/*
@media(max-width: 720px) {
    html { font-size: 87.5%; }
}



*/




body, button {
    font-family:'sans-serif';
}
button {
    cursor:pointer;
}
body, input, textarea, button {
    font: 400 1rem sans-serif;    
    background-color:var(--white);
    color:var(--text);
}
select,input body {font-size:1.5rem;}
* {
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color:inherit;
    text-decoration: underline;
}

.mainContainer {
    min-height: 100vh;
    max-width:992px;
    margin: 0 auto;
    padding: 0;
    display:flex;
    flex-direction: column;
    box-shadow: var(--background) 0px 0px 60px;
}

.mainContainer header {
    background-color:white;
    border-bottom:var(--gray-line) solid 2px;
    
    display:flex;
    align-content: center;
    justify-content: center;
  }
  .mainContainer header img {
    height:2rem;
    margin:0.25rem;
  }
  
  main {
    display:flex;
    flex: 1;
    background-color:var(--background);
    align-content: center;
    justify-content: center;
    overflow:hidden;
    position:relative;
    
  }
  
  main > section {    
    padding:0.5rem;
    flex:1;
    transition:transform ease 0.25s;
  }

  @media(min-width: 768px) {
    main > section { 
      transition:flex ease 0.25s;
    }
  }

  /*
  #EditPane {
    width: 0px;
    overflow: hidden;
    padding: 0px;
    flex: none;
  }

  #EditPane.active {
      flex:1;
      padding:0.5rem;
  }*/

  #EditPane {
    width: 0px;
    overflow: hidden;
    padding: 0px;
    flex: none;
    transform: translateX(100%);
  }

  #EditPane.active {
      flex:1;
      padding:0.5rem;
      transform: translateX(0%);
  }

  button.main-button {
    box-shadow: none;
    border:outset 3px var(--blue-dark);
    padding:0.3rem 0.5rem;
    background-color:var(--blue-dark);
    color:var(--white);
    display: flex;
    flex-direction: row;
    max-width:auto!important;
    margin: 0.5rem 0.25rem;
    
    border-radius:0.5rem;
    
}
 button.main-button:active  {
  border:inset 3px var(--blue-dark);
  
}

button.main-button#action-remover {
	background-color: var(--red);
	border-color: var(--red);	
}

button.main-button svg {  
    margin-right:0.5rem;
}

@media(max-width: 640px) {
  html { font-size: 87.5%; }
  #EditPane { font-size: 100%; }
  .itemOrcamento .actions .remove {display:none;}
  button.main-button svg {margin-top:2px;}
}